import * as PATHNAMES from 'configs/pathnames';

// use name attr for submenu keys, look at renderMenu func
export const MENU_DEFAULT_OPEN_KEYS = ['Inbox', 'Career'];

export const MENU_OPTION = [
  {
    type: 'SubMenu',
    name: 'Inbox',
    children: [
      {
        type: 'Menu',
        name: 'Cargo Owner',
        link: PATHNAMES.INBOX_CARGO_OWNER,
      },
      {
        type: 'Menu',
        name: 'Vessel Owner',
        link: PATHNAMES.INBOX_VESSEL_OWNER,
      },
      {
        type: 'Menu',
        name: 'Contact Us',
        link: PATHNAMES.INBOX_CONTACT_US,
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Career',
    children: [
      {
        type: 'Menu',
        name: 'Departement',
        link: PATHNAMES.CAREER_DEPARTEMENT,
      },
      {
        type: 'Menu',
        name: 'Job List',
        link: PATHNAMES.CAREER_JOBLIST,
      },
    ],
  },
];

const CARGO_OWNER_PATH_LIST = {
  [PATHNAMES.INBOX_CARGO_OWNER]: true,
  [PATHNAMES.INBOX_CARGO_OWNER_DETAILS]: true,
};

const VESSEL_OWNER_PATH_LIST = {
  [PATHNAMES.INBOX_VESSEL_OWNER]: true,
  [PATHNAMES.INBOX_VESSEL_OWNER_DETAILS]: true,
};

const CONTACT_US_PATH_LIST = {
  [PATHNAMES.INBOX_CONTACT_US]: true,
  [PATHNAMES.INBOX_CONTACT_US_DETAILS]: true,
};

const DEPARTMENT_PATH_LIST = {
  [PATHNAMES.CAREER_DEPARTEMENT]: true,
  [PATHNAMES.CAREER_DEPARTEMENT_CREATE_EDIT]: true,
};

const JOB_LIST_PATH_LIST = {
  [PATHNAMES.CAREER_JOBLIST]: true,
  [PATHNAMES.CAREER_JOBLIST_CREATE_EDIT]: true,
  [PATHNAMES.CAREER_JOBLIST_DETAILS]: true,
};

export const MENU_KEYS = {
  'Cargo Owner': CARGO_OWNER_PATH_LIST,
  'Vessel Owner': VESSEL_OWNER_PATH_LIST,
  'Contact Us': CONTACT_US_PATH_LIST,
  Departement: DEPARTMENT_PATH_LIST,
  'Job List': JOB_LIST_PATH_LIST,
};
