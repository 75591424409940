import React, { createContext, useContext } from 'react';

import { getAdminProfile } from 'api/admin-profile';
import { login as handleLogin } from 'api/auth';
import { useLocalStorage } from 'utils/hooks';

const AuthenticationContext = createContext();
export const useAuthContext = () => {
  const context = useContext(AuthenticationContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useUserContext was used outside of its Provider');
  }

  return context;
};

export function AuthenticationContextProvider({ children }) {
  const [adminProfile, setAdminProfile] = useLocalStorage('adminProfile', {});

  async function login(payload) {
    const { data: { data: { token } } = {} } = await handleLogin(payload);
    const {
      data: { data },
    } = await getAdminProfile(token);

    if (token && data) {
      const { name = '', email = '' } = data;
      localStorage.setItem('accessToken', token);
      setAdminProfile({ name, email });
    }

    window.location.reload();
  }

  return (
    <AuthenticationContext.Provider value={{ adminProfile, login }}>
      {children}
    </AuthenticationContext.Provider>
  );
}
