import { marineAPI } from './index';

export const login = ({ email, password } = {}) => {
  return marineAPI.post('/login', { email, password });
};

export const logout = () => {
  marineAPI.defaults.headers.common = {
    Authorization: null,
  };
  localStorage.clear();
};
