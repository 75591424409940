import { lazy } from 'react';

import * as PATHNAMES from 'configs/pathnames';

const CargoOwnerPage = lazy(() => import('pages/dashboard/cargo-owner'));
const CargoOwnerDetailsPage = lazy(() =>
  import('pages/dashboard/cargo-owner/details')
);
const ContactUsPage = lazy(() => import('pages/dashboard/contact-us'));
const ContactUsDetailsPage = lazy(() =>
  import('pages/dashboard/contact-us/details')
);

const DepartmentPage = lazy(() => import('pages/dashboard/department'));
const DepartmentCreateEditPage = lazy(() =>
  import('pages/dashboard/department/create-edit')
);

const JobListPage = lazy(() => import('pages/dashboard/job-list'));
const JobListCreateEditPage = lazy(() =>
  import('pages/dashboard/job-list/create-edit')
);
const JobListDetailsPage = lazy(() =>
  import('pages/dashboard/job-list/details')
);

const ApplicationPage = lazy(() => import('pages/dashboard/application'));
const ApplicationDetailPage = lazy(() =>
  import('pages/dashboard/application/details')
);

const VesselOwnerPage = lazy(() => import('pages/dashboard/vessel-owner'));
const VesselOwnerDetailsPage = lazy(() =>
  import('pages/dashboard/vessel-owner/details')
);

export const AUTH_ROUTES = [
  {
    path: PATHNAMES.INBOX_CARGO_OWNER,
    element: CargoOwnerPage,
  },
  {
    path: PATHNAMES.INBOX_CARGO_OWNER_DETAILS,
    element: CargoOwnerDetailsPage,
  },
  {
    path: PATHNAMES.INBOX_VESSEL_OWNER,
    element: VesselOwnerPage,
  },
  {
    path: PATHNAMES.INBOX_VESSEL_OWNER_DETAILS,
    element: VesselOwnerDetailsPage,
  },
  {
    path: PATHNAMES.INBOX_CONTACT_US,
    element: ContactUsPage,
  },
  {
    path: PATHNAMES.INBOX_CONTACT_US_DETAILS,
    element: ContactUsDetailsPage,
  },
  {
    path: PATHNAMES.CAREER_DEPARTEMENT,
    element: DepartmentPage,
  },
  {
    path: PATHNAMES.CAREER_DEPARTEMENT_CREATE_EDIT,
    element: DepartmentCreateEditPage,
  },
  {
    path: PATHNAMES.CAREER_JOBLIST,
    element: JobListPage,
  },
  {
    path: PATHNAMES.CAREER_JOBLIST_CREATE_EDIT,
    element: JobListCreateEditPage,
  },
  {
    path: PATHNAMES.CAREER_JOBLIST_DETAILS,
    element: JobListDetailsPage,
  },
  {
    path: PATHNAMES.CAREER_APPLICATIONS,
    element: ApplicationPage,
  },
  {
    path: PATHNAMES.CAREER_APPLICATIONS_DETAILS,
    element: ApplicationDetailPage,
  },
];
