import axios from 'axios';
import queryString from 'query-string';

export const getHeaders = () => {
  const accessToken = localStorage.getItem('accessToken');
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  };
};

export const createAPI = (
  baseURL = `${process.env.REACT_APP_BASE_URL}/cms`,
  config = {}
) => {
  const axiosInstance = axios.create({
    baseURL,
    withCredentials: false,
    timeout: 325000,
    ...config,
  });

  axiosInstance.interceptors.request.use((request) => {
    console.log('>>> Request', request);
    // Override brackets paramsSerializer, e.g. foo[]='a'
    request.paramsSerializer = (params) => queryString.stringify(params);
    return request;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      console.log('<<< Response:', response);
      return response;
    },
    async (error) => {
      console.log('*** ERROR', error);
      const { response } = error;

      if (response && (response.status === 403 || response.status === 401)) {
        // TODO IMPLEMENT REFRESH TOKEN
        console.log('token expired');
        localStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const marineAPI = createAPI();
