export const LOGIN_PATH = '/login';

export const HOME_PATH = '/';

export const INBOX_CARGO_OWNER = '/inbox/cargo-owner';
export const INBOX_CARGO_OWNER_DETAILS = '/inbox/cargo-owner/details';

export const INBOX_VESSEL_OWNER = '/inbox/vessel-owner';
export const INBOX_VESSEL_OWNER_DETAILS = '/inbox/vessel-owner/details';

export const INBOX_CONTACT_US = '/inbox/contact-us';
export const INBOX_CONTACT_US_DETAILS = '/inbox/contact-us/details';

export const CAREER_DEPARTEMENT = '/careers/departement';
export const CAREER_DEPARTEMENT_CREATE_EDIT =
  '/careers/departement/create-edit';

export const CAREER_JOBLIST = '/careers/job-list';
export const CAREER_JOBLIST_CREATE_EDIT = '/careers/job-list/create-edit';
export const CAREER_JOBLIST_DETAILS = '/careers/job-list/details';

export const CAREER_APPLICATIONS = 'careers/applications';
export const CAREER_APPLICATIONS_DETAILS = 'careers/applications/details';
