import React, { Suspense, lazy } from 'react';

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { PublicRoute, PrivateRoute } from 'components/guard';
import { TopProgressBar } from 'components/top-progress-bar';
import { LOGIN_PATH } from 'configs/pathnames';
import { useScrollToTop } from 'utils/hooks';

import { AUTH_ROUTES } from './constant';

const LoginPage = lazy(() => import('pages/login'));

export function AppRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  useScrollToTop();

  return (
    <Suspense fallback={<TopProgressBar />}>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="" element={<LoginPage />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="" element={<Navigate to={LOGIN_PATH} />} />
          {AUTH_ROUTES.map(({ path, element: RouteElement }, index) => (
            <Route
              element={<RouteElement location={location} navigate={navigate} />}
              key={index}
              path={path}
            />
          ))}
        </Route>
      </Routes>
    </Suspense>
  );
}
