export const parseLocationPathname = (pathname = '') => {
  return pathname.split('/').slice(1).join('') || '/';
};

export const omit = (obj = {}, keys = []) => {
  try {
    return Object.keys(obj).reduce((prev, curr) => {
      return keys.includes(curr) ? prev : { ...prev, [curr]: obj[curr] };
    }, {});
  } catch (error) {
    return {};
  }
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};
