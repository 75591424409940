import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import './styles.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export function TopProgressBar() {
  return <Spin indicator={antIcon} />;
}
