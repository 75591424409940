import { marineAPI } from 'api';

export const getAdminProfile = (accessToken) => {
  marineAPI.defaults.headers.common = {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return marineAPI.get('/admin/profile');
};
