import { useEffect, useState } from 'react';

import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

export const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => window.scrollTo(0, 0), [location.pathname]);
};

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export function usePagination(pathname, parsed, metaData) {
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  const stringifyParsedQuery = () => {
    return queryString.stringify(parsed, {
      skipEmptyString: true,
      skipNull: true,
    });
  };

  const resetPage = () => {
    parsed.page = null;
  };

  const onSearch = (value) => {
    parsed.search = value;
    resetPage();
    navigate({ pathname, search: stringifyParsedQuery() });
  };

  const handlePagination = (pagination) => {
    let { current, pageSize } = pagination;

    current = current - 1;

    if (+pageSize !== +metaData.per_page) {
      resetPage();
    } else {
      parsed.page = current;
    }

    parsed.limit = pageSize;
  };

  const handleSorter = (pagination, sorter) => {
    const { order, field } = sorter;
    parsed.order_by = field;
    parsed.order =
      order === 'descend' ? 'desc' : order === 'ascend' ? 'asc' : null;
    if (pagination.current === +metaData.current_page + 1) {
      resetPage();
    }
  };

  const onChangeTable = (pagination, _, sorter) => {
    handlePagination(pagination);
    handleSorter(pagination, sorter);
    navigate({ pathname: pathname, search: stringifyParsedQuery() });
  };

  useEffect(() => {
    if (parsed.search) {
      setSearchValue(parsed.search);
    }
  }, []);

  return {
    onSearch,
    resetPage,
    searchValue,
    setSearchValue,
    onChangeTable,
  };
}

export const useMultiSelect = (options = [], initialCheckedList = []) => {
  const [checkedList, setCheckedList] = useState(initialCheckedList);
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);

  const onChangeMultiSelect = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options.map(({ value }) => value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return {
    checkAll,
    checkedList,
    indeterminate,
    onChangeMultiSelect,
    onCheckAllChange,
    setCheckedList,
  };
};
