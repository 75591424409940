import { Outlet, Navigate } from 'react-router-dom';

import { LOGIN_PATH, INBOX_CARGO_OWNER } from 'configs/pathnames';

const getAuth = () => localStorage.getItem('accessToken');

export function PrivateRoute() {
  const isAuthenticated = getAuth();
  return isAuthenticated ? <Outlet /> : <Navigate to={LOGIN_PATH} />;
}

export function PublicRoute() {
  const isAuthenticated = getAuth();
  return isAuthenticated ? <Navigate to={INBOX_CARGO_OWNER} /> : <Outlet />;
}
