import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { PdLayout } from 'components/pd-layout';
import { MainProvider } from 'contexts';
import { AppRoutes } from 'routes';

import 'configs/antd.less';
import './App.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

function App() {
  return (
    <MainProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <PdLayout>
            <AppRoutes />
          </PdLayout>
        </BrowserRouter>
      </QueryClientProvider>
    </MainProvider>
  );
}

export default App;
