import { useRef, useState, useEffect } from 'react';

import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import { Layout, Menu } from 'antd';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { logout } from 'api/auth';
import { marineTechLogo } from 'configs/images';
import { LOGIN_PATH } from 'configs/pathnames';

import { MENU_OPTION, MENU_DEFAULT_OPEN_KEYS, MENU_KEYS } from './constant';
import './styles.scss';

const { Sider } = Layout;
const { Item, SubMenu } = Menu;

function renderMenu(opt) {
  const { name = '', link = '', type = 'Menu', children = [] } = opt;

  if (type === 'Menu') {
    return (
      <Item key={name}>
        <Link className="menu-item" to={link}>
          {name}
        </Link>
      </Item>
    );
  } else {
    return (
      <SubMenu className="menu-item" key={name} title={<span>{name}</span>}>
        {children.map((opt) => renderMenu(opt))}
      </SubMenu>
    );
  }
}

export function SideBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const sideBarRef = useRef(null);
  const [selectedKeys, setSelectedKeys] = useState('Cargo Owner');

  const handleOnMouseEnter = () => {
    if (sideBarRef.current) {
      sideBarRef.current.classList.remove('hide-scroll-bar');
    }
  };

  const handleOnMouseLeave = () => {
    if (sideBarRef.current) {
      sideBarRef.current.classList.add('hide-scroll-bar');
    }
  };

  useEffect(() => {
    setSelectedKeys(() => {
      const listKey = Object.keys(MENU_KEYS);
      let result;
      for (let i = 0; i < listKey.length; i++) {
        const key = listKey[i];
        if (location.pathname in MENU_KEYS[key]) {
          result = key;
          break;
        }
      }
      return result;
    });
  }, [location.pathname]);

  return (
    <Sider
      className="pd-sidebar hide-scroll-bar"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      ref={sideBarRef}
      theme="light"
      width={250}>
      <div className="logo">
        <img src={marineTechLogo} />
      </div>
      <Menu
        className="menu"
        defaultOpenKeys={MENU_DEFAULT_OPEN_KEYS}
        selectedKeys={selectedKeys}
        mode="inline">
        {MENU_OPTION.map((opt) => renderMenu(opt))}
      </Menu>
      <div
        className="logout"
        onClick={() => {
          logout();
          navigate(LOGIN_PATH, { replace: true });
        }}>
        <LogoutOutlined />
        <span>Logout</span>
      </div>
    </Sider>
  );
}
