import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';

import { SideBar } from 'components/sidebar';
import { LOGIN_PATH } from 'configs/pathnames';
import { parseLocationPathname } from 'utils';

export function PdLayout({ children }) {
  const location = useLocation();
  const parsedCurrentPath = parseLocationPathname(location.pathname);
  const parsedLoginPath = parseLocationPathname(LOGIN_PATH);

  return parsedCurrentPath === parsedLoginPath ? (
    children
  ) : (
    <Layout hasSider>
      <SideBar />
      {children}
    </Layout>
  );
}
